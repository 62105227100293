.banner {
	height: 90vh;
	display: grid;
	place-items: center;
	text-align: center;
	background-image: url(../images/AddisAbaba.jpg);
	background-size: cover;
	background-position: center center;
	position: fixed; 
    top: 0; 
    width: 100%; 
	height: 100vh;
	z-index: 1;
}

.banner-text h1 {
	font-size: 45px;
	color: #fff;
}

.banner-text p {
	font-size: 18px;
	margin-top: 15px;
	color: #fff;
}

.banner-text a {
	text-decoration: none;
	background: #F2A30F;
	color: #fff;
	display: inline-block;
	padding: 15px 25px;
	margin-top: 25px;
	border-radius: 50px;
	text-transform: uppercase;

}

.hover-arrow {
	transition: all 0.3s;
}

.hover-arrow:hover {
	margin-right: 15px;
	transition: all 0.3s;
}

.banner:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: -1;
}

/* Rest of the page */ 
.rest-page {
    position: relative;
    margin-top: 100vh;
	z-index: 2;
}

/* About Section */ 

.about{
	min-height: 80vh;
	background: #16222A;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 5% 0;
}
  
  .about .row{
	display: flex;
	align-items: center;
	width:90%;
	margin:0 auto;
  }
  
  .about .row .image {
	box-sizing: content-box;
	height: auto;
	max-width: 100%;
    overflow: hidden;
	 position: relative;
  }

  .about .row .image img{
    max-width: 100%;
    height: auto;
	padding: 5%;
    border-radius: 10%;
	transform: scale(1.0);
    transition: transform 0.4s ease;
  }

  .team-caption {
    position: absolute;
    bottom: 5px;
    left: 20px;
    opacity: 0.0;
    transition: transform 0.3s ease, opacity 0.3s ease;
	color: white;
  }
  

  .about .row .image:hover img { 
    transform: scale(1.1);
  }
  
  .about .row .image:hover .transparent-box {
    background-color:rgba(0, 0, 0, 0.5);
  }
  
  .about .row .image:hover .team-caption {
    transform: translateY(-50px);
    opacity: 1.0;
  }
  
  .about .row .image:hover {
    cursor: pointer;
  }
  .about .row .content{
      width: 70%
  }
  
  .about .row .content h3{
	font-size: 3rem;
	color: #F2A30F;
  }
  
  .about .row .content p{
	font-size: 1.2rem;
	color:#fff;
	
  }
  
  .about a {
   text-decoration: none;
  }

  .about .row .content .home-btn{
	outline: none;
	border:none;
	display: inline-block;
	cursor: pointer;
	transition: .2s linear;
	height:4rem;
	width:18rem;
	margin-top: 1rem;
	color:#fff;
	background: #F2A30F;
	font-size: 2rem;
  }
  


  .about .row .content .home-btn1,
  .about .row .content .home-btn:hover{
	background: none;
	color: #F2A30F;
	border:.2rem solid #F2A30F;
	margin-right: 1rem;
	display: inline-block;
  }

  
  

/* media queries  */


@media (max-width: 1024px){
  
	.about .row{
	  flex-flow: column;
	}
  
	.about .row .image img{
		max-width: 100%;
		height: auto;
	}

	.about .row .content{
		width: 100%;
	}
  
	.about .row .content{
	  text-align: center;
	  font-size: 55%;
	}

   .about .row .content .home-btn1{
	  margin-right: 0;
	  margin-right: 0;
	}

	.button-container {
		margin: 1rem;
	  }
	
	  .button-container a {
		margin: 1rem;
	  }
	

  }
  @media (max-width: 540px){
	.about .row .image img{
		max-width: 100%;
		height: auto;
	}

	.about .row .content{
		width: 100%;	
	}

	.about .row .content .button-container {
	     margin: auto;
	}
  
	  .about .row .content .home-btn1{
		margin-right: 0;
		margin-right: 0;
	  }
	  
	  .button-container {
		margin: 1rem;
	  }
	
	  .button-container a {
		margin: 1rem;
	  }
	
  
  }

/* Services */ 
#services {
	background-color: #263746;
}

.services {
	padding: 10% 0;
}

.service-header {
	font-size: 3rem;
	color: #F2A30F;
	display: flex;
	align-items: center;
	justify-content: center;
}

.services .box-container{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
  }
  
.services .box-container .box{
	min-height: 20rem;
	max-height: auto;
	max-width: 25rem;
	min-width: auto;
	background:#fff;
	text-align: center;
	border-radius: 1rem;
	box-shadow: 0 .3rem .5rem rgba(0,0,0,.5);
	margin: 2rem;
	cursor: pointer;
  }
  
.services .box-container .box i{
	height:6rem;
	width:6rem;
	line-height: 6rem;
	text-align: center;
	border-radius: 50%;
	color:#fff;
	background:var(--violet);
	font-size: 3rem;
	margin:2rem 0;
	transition: .2s;
  }
  
  .services .box-container .box h3{
	font-size: 2rem;
	color: #263746;
	transition: .2s;
  }
  
  .services .box-container .box p{
	font-size: 1.3rem;
	padding:.7rem 2rem;
	color:#666;
  }

  .service-link {
	text-decoration: none;
  }
  
  .services .box-container .box:hover .icons{
	color: #263746;
  }
  
  .services .box-container .box:hover h3{
	color: #F2A30F;
  }

  .icons {
	color: #F2A30F;
	font-size: large;
	margin-top: 10%;
	margin-bottom: 5%;
  }
  
  .box:hover {
    transform: translateY(-3%);
	transition: 0.3s ease-in-out;
  }
/* Contact Section */ 

.contact {
	background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	padding: 5% 0;
 }

 .contact h3 {
	font-size: 3rem;
	color: #F2A30F;
	display: flex;
	align-items: center;
	justify-content: center;
 }

 .contact .row{
	display: flex;
	align-items: center;
	justify-content: space-around;
  }
  
  .contact .row .contact-info .box{
	margin:2rem 0rem 2rem 1rem;
	display: flex;
	flex-direction: column;
    align-items: flex-start;
  }

  .contact .row .contact-info .box a {
	text-decoration: none;
  }

  
  .contact .row .contact-info .box h3{
	font-size: 1.5rem;
	color:#fff;
  }
  
  .contact .row .contact-info .box h3 i{
	color:#F2A30F;
  }
  
  .contact .row .contact-info .box p{
	padding-left: 3rem;
	font-size: 1.2rem;
	color: #BBBBBB;
  }

  .contact .row .contact-info .box p:hover{
	color: #fff;
	transform: translateY(-5%);
	transition: 0.3s ease-in-out;
  }
  
  .contact .row .contact-form-container{
	width:50%;
  }
  
  .contact .row .contact-form-container h3{
	font-size: 2.5rem;
	padding-top: 2rem;
	color:#F2A30F;
  } 
  
  .contact .row .contact-form-container .inputBox{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
  }
  
  .contact .row .contact-form-container .inputBox input{
	width: 49%;
  }
  
  .contact .row .contact-form-container form input, textarea{
	outline: none;
	border:.2rem solid #F2A30F;
	height:4rem;
	background:#fff;
	padding:0 1rem;
	margin:1rem 0;
	font-size: 1.8rem;
  }
  
  .contact .row .contact-form-container form textarea{
	width:100%;
	padding:1rem;
	resize: none;
	height:20%;
  }
  
  .contact .row .contact-form-container form input[type="submit"]{
	color:#F2A30F;
	border:.2rem solid #F2A30F;
	cursor: pointer;
	transition: .2s;
	width:20rem;
  }
  
  .contact .row .contact-form-container form input[type="submit"]:hover{
	color:#fff;
	background:#F2A30F;
  }
  
  /* Media query */

@media (max-width:768px){
    .contact .row{
	  flex-flow: column;
	  align-items: flex-start;
	}
  
	.contact .row .contact-form-container{
	  width:90%; 
	  margin:0 auto;
	} 
  }
  
  @media (max-width:500px){
  
	.form-container{
	  width: 100%;
	}
   .form-container form{
	  width: 100%;
	  margin:3rem 0;
	}
  
	.form-container form input[type="submit"]{
	  width: 100%;
	}
  

	.contact .row .contact-form-container form input{
	  margin:1rem 0;
	  width:100%;
	}
 
	.contact .row .contact-form-container form input[type="submit"]{
	  width:100%;
	}

	.contact .row .contact-form-container form input[type="text"]{
		width:100%;
		
	  }
   
	.contact .row .contact-form-container form input[type="email"]{
		width:100%;
		
	  }
	
}

/* For samsung galaxy fold */ 

@media(max-width: 280px) {

	.contact .row .contact-info .box h3{
		font-size: 1.2rem;
		color:#fff;
	  }

	.contact .row .contact-info .box p{
		padding-left: 1rem;
		font-size: 1rem;
		color: #BBBBBB;
	  }

	
  .about .row .content .home-btn{
	
	height:4rem;
	width:15rem;

  }

  .rest-page {
    position: relative;
    margin-top: 80vh;
	z-index: 2;
}
  
  .banner {
	height: 90vh;
	display: grid;
	place-items: center;
	text-align: center;
	background-image: url(../images/AddisAbaba.jpg);
	background-size: cover;
	background-position: center center;
	position: absolute; 
    top: 0; 
    width: 100%; 
	height: 100vh;
	z-index: 1;
}

.about .row .content p{
	font-size: 1rem;
   
	color:#fff;
	
  }

  .services .box-container .box{
	height: 320px;
	width: 260px;
	background:#fff;
	text-align: center;
	border-radius: 1rem;
	box-shadow: 0 .3rem .5rem rgba(0,0,0,.5);
	margin: 2rem;
	cursor: pointer;
  }
  
  .services .box-container .box h3{
	font-size: 1.5rem;
	color: #263746;
	transition: .2s;
  }
  
  .services .box-container .box p{
	font-size: 1.3rem;
	padding:.7rem 2rem;
	color:#666;
  }

  .about .row .content h3{
	font-size: 1.5rem;
	color: #F2A30F;
  }
  
  .about .row .content p{
	font-size: 1rem;
	padding:.7rem 1rem;
	color:#fff;
	
  }

  .services {
	overflow-x: hidden;
  }
  .about {
	overflow-x: hidden;
  }
}