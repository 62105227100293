

:root {

  --clr-neutral-100: #fff;
  --clr-neutral-300: #ddd;
  --clr-neutral-500: #555555;
  --clr-neutral-800: #111827;
  --clr-primary: #F2A30F;
  --transition: 250ms ease-in-out;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.services-display-page {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  place-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: var(--clr-neutral-300);
  line-height: 1.5;
  font-size: 1rem;
}

.services-display-page h1,
h2,
h3 {
  line-height: 1.1;
}

.card__img {
  max-width: 100%;
  display: block;
}

.services-display-page a {
  text-decoration: none;
  color: inherit;
}

.services-display-page .card {
  display: grid;
  position: relative;
  margin: 2rem;
  width: min(100% - 2rem, 380px);
  margin-block: 2rem;
  background-color: var(--clr-neutral-100);

  overflow: hidden;
  border-radius: 0.8em;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
  transition: var(--transition);
}

.services-display-page .card:is(:hover, :focus) {
  transform: translateY(-5px);
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.1);
}

.services-display-page .card:focus-visible {
  outline: 2px dashed;
  outline-offset: 0.4em;
}

.services-display-page .card__img-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  aspect-ratio: 16 / 9;
}

/* image overlay */
.services-display-page .card__img-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  z-index: 1;
  transition: var(--transition);
}

.services-display-page .card__tags {
  --_pad: 10px;
  position: absolute;
  inset: var(--_pad) var(--_pad) auto var(--_pad);

  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.25em;

  opacity: 0;
  transition: var(--transition);
}

.services-display-page .card__tag {
  display: inline-block;
  padding: 0.1em 0.5em;

  font-size: 14px;
  text-transform: uppercase;

  background-color: rgba(0, 0, 0, 0.6);
  color: var(--clr-neutral-100);
  border-radius: 5px;
  z-index: 2;
}

.services-display-page .card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.services-display-page .card__body {
  padding: 1em 1.5em;
}

.services-display-page .card__title {
  margin: 0;
  font-size: clamp(1.4rem, 4vw, 1.7rem);
  text-transform: capitalize;
  color: var(--clr-neutral-800);
}

.services-display-page .card__date {
  font-size: 0.875rem;
  color: var(--clr-neutral-500);
}

.services-display-page .card__cta {
  position: relative;
  width: fit-content;
  margin-top: auto;
  color: var(--clr-primary);
}

/* underline */
.services-display-page .card__cta::before {
  content: "";
  position: absolute;
  inset: 100% 0 0 0;
  height: 2px;

  background-color: currentColor;
  transition: var(--transition);
  transform-origin: right;
}

/* right arrow */
.services-display-page .card__cta::after {
  content: " →";
  display: inline-block;
  margin-left: 5px;
  transition: var(--transition);
}

/* image overlay */
.services-display-page .card:is(:hover, :focus) .card__img-container::before {
  opacity: 1;
}

/* remove underline */
.services-display-page .card:is(:hover, :focus) .card__cta::before {
  transform: scale(0);
}

/* move arrow */
.services-display-page .card:is(:hover, :focus) .card__cta::after {
  transform: translateX(6px);
}

/* show tag */
.services-display-page .card:is(:hover, :focus) .card__tags {
  opacity: 1;
}


@media (max-width:768px){
    .services-display-page .card {
        margin: 1rem;
    }
  }
  
  @media (max-width:500px){
    .services-display-page .card {
        margin: 1rem;
    }
}

