.underwriting-header {
    background:  linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3)), url(../images/Underwriting.jpg);
    padding-top: 3%;
    padding-bottom: 3%;
    color: white;
    text-align: center;
    background-size: 100%;
    background-position-y: 80%;
}

.uw-page{
    background: #eee;
}

.uw-page .uw-desc-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 3% 25% 3% 25%;
}

.uw-page .uw-desc-box .uw-content h3{
    font-size: 1.7rem;
}
.uw-page .uw-desc-box .uw-content p{
    font-size: 1.1rem;
    margin: 2rem 0;
    color:#666;
}

@media screen and (max-width: 914px) {
    .uw-page .uw-desc-box{
        padding: 5% 5% 5% 5%;
    }
    
}

@media screen and (max-width: 990px) {
    .uw-page .uw-desc-box{
        padding: 5% 5% 5% 5%;
    }
}
