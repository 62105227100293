.contact-us {
	background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.3)), url(../images/Contact-Us.jpg);
	background-size: cover;
	background-position: center center;
    width: 100%; 
	padding: 2%
 }

 .contact-us h3 {
	font-size: 3rem;
	color: #F2A30F;
	display: flex;
	align-items: center;
	justify-content: center;
 }

 .contact-us .row{
	display: flex;
	align-items: center;
	justify-content: space-around;
  }
  
  .contact-us .row .contact-info .box{
	margin:2rem 0rem 2rem 1rem;
	display: flex;
	flex-direction: column;
    align-items: flex-start;
  }

  .contact-us .row .contact-info .box a {
	text-decoration: none;
  }

  
  .contact-us .row .contact-info .box h3{
	font-size: 1.5rem;
	color:#fff;
  }
  
  .contact-us .row .contact-info .box h3 i{
	color:#F2A30F;
  }
  
  .contact-us .row .contact-info .box p{
	padding-left: 3rem;
	font-size: 1.2rem;
	color: #BBBBBB;
  }

  .contact-us .row .contact-info .box p:hover{
	color: #fff;
	transform: translateY(-5%);
	transition: 0.3s ease-in-out;
  }
  
  .contact-us .row .contact-form-container{
	width: 40%;
  }
  
  .contact-us .row .contact-form-container h3{
	font-size: 2.5rem;
	padding-top: 2rem;
	color:#F2A30F;
  } 
  
  .contact-us .row .contact-form-container .inputBox{
	display: flex;
	flex-direction: column;
  }
  
  
  .contact-us .row .contact-form-container .inputBox input{
	width: 100%;
  }
  
  .contact-us .row .contact-form-container form input, textarea{
	outline: none;
	border:.2rem solid #F2A30F;
	height:4rem;
	background:#fff;
	padding:0 1rem;
	margin:1rem 0;
	font-size: 1.8rem;
  }
  
  .contact-us .row .contact-form-container form textarea{
	width:100%;
	padding:1rem;
	resize: none;
	height:20%;
  }
  
  .contact-us .row .contact-form-container form input[type="submit"]{
	color:#F2A30F;
	border:.2rem solid #F2A30F;
	cursor: pointer;
	transition: .2s;
	width:20rem;
  }
  
  .contact-us .row .contact-form-container form input[type="submit"]:hover{
	color:#fff;
	background:#F2A30F;
  }

  .contact-us .row .contact-form-container p {
	color: red;
	font-size: 1.5rem;
  }

  .loading-image {
	width:15%;
  }

  .btn-alignment {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }

  .input-alignmnet  {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
  
  /* Media query */

@media (max-width:768px){
    .contact-us .row{
	  flex-flow: column;
	  align-items: flex-start;
	}
  
	.contact-us .row .contact-form-container{
	  width:90%; 
	  margin:0 auto;
	} 
  }
  
  @media (max-width:500px){
  
	.contact-us .form-container{
	  width: 100%;
	}
   .contact-us .form-container form{
	  width: 100%;
	  margin:3rem 0;
	}
  
	.contact-us .form-container form input[type="submit"]{
	  width: 100%;
	}
  

	.contact-us .row .contact-form-container form input{
	  margin:1rem 0;
	  width:100%;
	}
 
	.contact-us .row .contact-form-container form input[type="submit"]{
	  width:100%;
	}

	.contact-us .row .contact-form-container form input[type="text"]{
		width:100%;
		
	  }
   
	.contact-us .row .contact-form-container form input[type="email"]{
		width:100%;
		
	  }
	
}

/* For samsung galaxy fold */

@media(max-width: 280px) {

	.contact-us .row .contact-info .box h3{
		font-size: 1.2rem;
		color:#fff;
	  }

	.contact-us .row .contact-info .box p{
		padding-left: 1rem;
		font-size: 1rem;
		color: #BBBBBB;
	  }

	


}