.login{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

    input {
        width: 200px;
        height: 30px;
        margin: 10px;
    }

    button {
        width: 200px;
        height: 30px;
        border: none;
        background-color: white;
        cursor: pointer;
        font-weight: bold;
    }

    
    button:hover {
        width: 200px;
        height: 30px;
        border: none;
        background-color: #263746;
        cursor: pointer;
        font-weight: bold;
		color: white;
    }

    span {
        font-size: 12;
        color: red;
        margin-top: 10px;
    }
}
}