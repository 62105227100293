.about-page{
    background: #eee;
}

.about-h1 {
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
    background: #BA8B02;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #181818, #BA8B02);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #181818, #BA8B02); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
}


.about-page .desc-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 3% 25% 3% 25%;
}

.about-page .desc-box .content h3{
    font-size: 1.7rem;
}
.about-page .desc-box .content p{
    font-size: 1.1rem;
    margin: 2rem 0;
    color:#666;
}

.download-header {
    padding-bottom: 20px;
}

.about-page .desc-box a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 3% 0% 3% 0%;
}


@media screen and (max-width: 914px) {
    .about-page .desc-box{
        padding: 5% 5% 5% 5%;
    }
    
}

@media screen and (max-width: 990px) {
    .about-page .desc-box{
        padding: 5% 5% 5% 5%;
    }
}
