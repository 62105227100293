
footer{
    background: #263746;
    width: 100%;
  }
  footer::before{
    content: '';
    left: 0;
    top: 100px;
    height: 1px;
    width: 100%;
    background: #AFAFB6;
  }
  footer .content{
    max-width: 1250px;
    margin: auto;
    padding: 30px 40px 40px 40px;
  }
  footer .content .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  
  .content .top .media-icons{
    display: flex;
  }
  .content .top .media-icons a{
    height: 40px;
    width: 40px;
    margin: 0 8px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  .top .media-icons a:nth-child(1){
    background: #4267B2;
  }
  .top .media-icons a:nth-child(1):hover{
    color: #4267B2;
    background: #fff;
  }
  .top .media-icons a:nth-child(2){
    background: #1DA1F2;
  }
  .top .media-icons a:nth-child(2):hover{
    color: #1DA1F2;
    background: #fff;
  }
  .top .media-icons a:nth-child(3){
    background: #E1306C;
  }
  .top .media-icons a:nth-child(3):hover{
    color: #E1306C;
    background: #fff;
  }
  .top .media-icons a:nth-child(4){
    background: #0077B5;
  }
  .top .media-icons a:nth-child(4):hover{
    color: #0077B5;
    background: #fff;
  }
  .top .media-icons a:nth-child(5){
    background: #FF0000;
  }
  .top .media-icons a:nth-child(5):hover{
    color: #FF0000;
    background: #fff;
  }
  footer .content .link-boxes{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  footer .content .link-boxes .box{
    width: calc(100% / 5 - 10px);
  }
  .content .link-boxes .box .link_name{
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
  }
  .link-boxes .box .link_name::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 35px;
    background: #fff;
  }
  .content .link-boxes .box li{
    margin: 6px 0;
    list-style: none;
  }
  .content .link-boxes .box li a{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.4s ease
  }
  .content .link-boxes .box li a:hover{
    opacity: 1;
    text-decoration: underline;
  }
  
  
  footer .bottom-details{
    width: 100%;
    background: #263746;
  }
  footer .bottom-details .bottom_text{
    max-width: 1250px;
    margin: auto;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
  }
  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a{
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
  }
  .bottom-details .bottom_text a:hover{
    opacity: 1;
    text-decoration: underline;
  }
  .bottom-details .bottom_text a{
    margin-right: 10px;
  }
  @media (max-width: 912px) {
      footer{
          position: relative;
        }
    footer .content .link-boxes{
      flex-wrap: wrap;
    }
    footer .content .link-boxes .input-box{
      width: 40%;
      margin-top: 10px;
    }
  }
  @media (max-width: 700px){
    footer{
      position: relative;
    }
    .content .top .logo-details{
      font-size: 26px;
    }
    .content .top .media-icons a{
      height: 35px;
      width: 35px;
      font-size: 14px;
      line-height: 35px;
    }
    footer .content .link-boxes .box{
      width: calc(100% / 3 - 10px);
    }
    footer .content .link-boxes .input-box{
      width: 60%;
    }
    .bottom-details .bottom_text span,
    .bottom-details .bottom_text a{
      font-size: 12px;
    }
  }
  @media (max-width: 520px){
  
    footer::before{
      top: 145px;
    }
    footer .content .top{
      flex-direction: column;
    }
    .content .top .media-icons{
      margin-top: 16px;
    }
    footer .content .link-boxes .box{
      width: calc(100% / 2 - 10px);
    }
    footer .content .link-boxes .input-box{
      width: 100%;
    }
  }
  
  