* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color:  #eee;
  }

  .team-h1 {
    padding-top: 2%;
    padding-bottom: 2%;
    text-align: center;
    background: #BA8B02;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #181818, #BA8B02);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #181818, #BA8B02); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
  }
  
  .gallery-image {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .gallery-image img {
    height: 300px;
    width: 300px;
    transform: scale(1.0);
    transition: transform 0.4s ease;
  }
  
  .img-box {
    box-sizing: content-box;
    margin: 10px;
    height: 300px;
    width: 300px;
    overflow: hidden;
    display: inline-block;
    color: white;
    position: relative;
    background-color: white;
  }
  
  .caption {
    position: absolute;
    bottom: 5px;
    left: 20px;
    opacity: 0.0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .transparent-box {
    height: 300px;
    width: 300px;
    background-color:rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
  }
  
  .img-box:hover img { 
    transform: scale(1.1);
  }
  
  .img-box:hover .transparent-box {
    background-color:rgba(0, 0, 0, 0.5);
  }
  
  .img-box:hover .caption {
    transform: translateY(-20px);
    opacity: 1.0;
  }
  
  .img-box:hover {
    cursor: pointer;
  }
  
  .caption > p:nth-child(2) {
    font-size: 0.8em;
  }
  
  .opacity-low {
    opacity: 0.5;
    padding-bottom: 5px;
  }

  @media(max-width: 280px) {

    .gallery-image img {
        height: 200px;
        width: 200px;
        transform: scale(1.0);
        transition: transform 0.4s ease;
      }

      .img-box {
        box-sizing: content-box;
        margin: 10px;
        height: 200px;
        width: 200px;
        overflow: hidden;
        display: inline-block;
        color: white;
        position: relative;
        background-color: white;
      }

      .transparent-box {
        height: 200px;
        width: 200px;
        background-color:rgba(0, 0, 0, 0);
        position: absolute;
        top: 0;
        left: 0;
        transition: background-color 0.3s ease;
      }
}